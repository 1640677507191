import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import ContactMe from "../components/contactMe"
import MainHero from "../components/mainHero"
import RenderMarkdown from "../cms/renderMarkdown"
import SEO from "../components/SEO"

const Problems = ({
  data: {
    content: { frontmatter, body },
  },
}) => {
  const renderImageSquare = (title, fluid, url) => {
    return (
      <div className="column is-half" key={title}>
        <Link to={`/common-problems${url}`} style={{ textDecoration: "none" }}>
          <Img
            fluid={fluid}
            style={{
              maxWidth: "510px",
              // borderRadius: "128px",
              // display: "block",
              margin: "auto",
            }}
          />
          <h2
            className="has-text-centered"
            style={{
              paddingBottom: "3rem",
              paddingTop: "1.6rem",
            }}
          >
            {title}
          </h2>
        </Link>
      </div>
    )
  }

  return (
    <Layout>
      <SEO title="Common Problems | Sydney Reiter Counseling | Birmingham, MI" />
      <MainHero
        heroImage={frontmatter.hero_image}
        title={frontmatter.title}
        subTitle={frontmatter.subTitle}
        height="is-medium"
        crop="center"
      />
      <section className="section">
        <div className="container">
          <div className="columns is-multiline is-centered">
            {frontmatter.problems_.map(problem =>
              renderImageSquare(
                problem.problem_title,
                problem.problem_image.childImageSharp.fluid,
                problem.page_url
              )
            )}
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div
            style={{
              maxWidth: "850px",
              margin: "auto",
            }}
          >
            <RenderMarkdown md={body} />
          </div>
        </div>
      </section>
      <ContactMe />
    </Layout>
  )
}

export default Problems

export const query = graphql`
  {
    content: mdx(frontmatter: { section: { eq: "problems" } }) {
      frontmatter {
        hero_image {
          childImageSharp {
            sizes(maxWidth: 1920) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        title
        subTitle
        problems_ {
          problem_title
          problem_image {
            childImageSharp {
              fluid(maxHeight: 340, maxWidth: 510) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          page_url
        }
      }
      body
    }
  }
`
